import { Translations } from "./de";

export const en: Translations = {
  bookNextHolidayHere: {
    formal: "Book your next holiday here",
    informal: "Book your next holiday here",
  },
  yourHolidayWithUs: {
    formal: "Your holiday with us",
    informal: "Your holiday with us",
  },
  ourAmenities: "Our amenities",
  southTyrol: "South Tyrol",
  italy: "Italy",
  imprint: "Imprint",
  privacy: "Privacy",
  contact: "Contact",
  propertyNotBookable:
    "Unfortunately, this accommodation is currently not bookable online.",
  showMore: "show more",
  showLess: "show less",
  enableJavaScript: "Activate JavaScript in your browser to be able to book.",
  errorLoadingWidget:
    "An error occurred while loading the booking widget. Please try again later.",
};
