import useEmblaCarousel from "embla-carousel-react";
import { Image } from "~/types";
import { getHeight, getMSSImageSrcSet, getMSSImageURL } from "~/utils";
import { LeftRightArrow } from "./build/svg";
import Icon from "./icon";
import styles from "./slider.module.css";

interface Props {
  images: Image[];
}

const defaultAspectRatio = 1;
const aspectRatios = [2, 3 / 4, 16 / 9, 4 / 3] as const;
const repeatCount = aspectRatios.length;
const imageWidths = [1440, 1024, 640, 360];

export default function Slider({ images }: Props) {
  const [emblaRef] = useEmblaCarousel({ loop: true, skipSnaps: true });

  return (
    <div className={styles.slider}>
      <div className={styles.viewport} ref={emblaRef}>
        <div className={styles.container}>
          {images.map((image, index) => {
            const aspectRatio =
            aspectRatios[index % repeatCount] ?? defaultAspectRatio;

            return (
              <div className={styles.slide} key={image.url}>
                <img
                  className={styles.image}
                  loading="lazy"
                  src={getMSSImageURL({
                    url: image.url,
                    width: 1024,
                    aspectRatio
                  })}
                  srcSet={getMSSImageSrcSet({
                    baseImageURL: image.url,
                    widths: imageWidths,
                    aspectRatio
                  })}
                  alt={image.title ?? ""}
                  width={1024}
                  height={getHeight(1024, aspectRatio)}
                  sizes="(min-width: 1024px) 35vw, 70vw" />

              </div>);

          })}
        </div>
      </div>
      <Icon className={styles.arrows} component={LeftRightArrow} />
    </div>);

}