import { translate } from "~/i18n";
import { APIProperty, Language } from "~/types";
import styles from "./about.module.css";
import Heading from "./heading";

interface Props {
  language: Language;
  property: APIProperty;
}

export default function About({
  language,
  property: { description, guestInteraction }
}: Props) {
  const trans = translate(language);
  return (
    <section id="description" className={styles.about}>
      <div className={styles.container}>
        <Heading
          level="h2"
          title={trans.yourHolidayWithUs[guestInteraction]}
          withSeparator={true} />


        <div className={styles.body}>
          {description &&
          <div dangerouslySetInnerHTML={{ __html: description }} />
          }
        </div>
      </div>
    </section>);

}