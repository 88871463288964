import classNames from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import { useCallback, useEffect, useState } from "react";
import { Image } from "~/types";
import { getHeight, getMSSImageSrcSet, getMSSImageURL } from "~/utils";
import { ArrowBack, ArrowForward } from "./build/svg";
import styles from "./hero.module.css";
import Icon from "./icon";

const wideAspectRatio = 24 / 9;
const narrowAspectRatio = 1;
const imageWidths = [2560, 1920, 1440, 1024, 640, 360];

interface Props {
  images: Image[];
}

export default function Hero({ images }: Props) {
  const imagesCount = images.length;
  const [emblaRef, emblaAPI] = useEmblaCarousel({
    loop: true,
    watchDrag: imagesCount > 1
  });
  const [selectedIndex, setSelectedIndex] = useState(0);

  const scrollPrev = useCallback(() => emblaAPI?.scrollPrev(), [emblaAPI]);
  const scrollNext = useCallback(() => emblaAPI?.scrollNext(), [emblaAPI]);
  const scrollTo = useCallback(
    (index: number) => emblaAPI?.scrollTo(index),
    [emblaAPI]
  );

  const onSelect = useCallback(() => {
    if (!emblaAPI) return;
    setSelectedIndex(emblaAPI.selectedScrollSnap());
  }, [emblaAPI, setSelectedIndex]);

  useEffect(() => {
    if (!emblaAPI) return;
    onSelect();
    emblaAPI.on("select", onSelect);
    emblaAPI.on("reInit", onSelect);
  }, [emblaAPI, onSelect]);

  return (
    <div
      className={classNames(styles.hero, {
        [styles.heroDraggable ?? ""]: imagesCount > 1
      })}
      ref={emblaRef}>

      <div className={styles.container}>
        {images.map((image, index) =>
        <div key={image.url} className={styles.slide}>
            <picture>
              <source
              srcSet={getMSSImageSrcSet({
                baseImageURL: image.url,
                widths: imageWidths,
                aspectRatio: wideAspectRatio
              })}
              media="(min-width: 768px)"
              width={1920}
              height={getHeight(1920, wideAspectRatio)}
              sizes="100vw" />


              <img
              className={styles.image}
              // Only load the first image eagerly on page load,
              // then always load the next slider image eagerly
              // to minimize blank spaces when the images are loading.
              loading={
              index - (selectedIndex === 0 ? 0 : 1) > selectedIndex ?
              "lazy" :
              "eager"
              }
              src={getMSSImageURL({
                url: image.url,
                width: 1920,
                aspectRatio: narrowAspectRatio
              })}
              srcSet={getMSSImageSrcSet({
                baseImageURL: image.url,
                widths: imageWidths,
                aspectRatio: narrowAspectRatio
              })}
              sizes="100vw"
              alt={image.title ?? ""}
              width={1920}
              height={getHeight(1920, narrowAspectRatio)} />

            </picture>
          </div>
        )}
      </div>
      {imagesCount > 1 &&
      <>
          <button
          className={classNames(styles.arrowButton, styles.arrowLeft)}
          onClick={scrollPrev}>

            <Icon className={styles.arrowIcon} component={ArrowBack} />
          </button>
          <button
          className={classNames(styles.arrowButton, styles.arrowRight)}
          onClick={scrollNext}>

            <Icon className={styles.arrowIcon} component={ArrowForward} />
          </button>
        </>
      }

      {imagesCount > 1 &&
      <ul className={styles.dots}>
          {emblaAPI?.
        scrollSnapList()
        // Show a maximum of 5 slider navigation dots.
        .slice(0, 5).
        map((_, index) =>
        <li key={index}>
                <button
            className={classNames(styles.dotButton, {
              [styles.dotButtonActive ?? ""]: selectedIndex === index
            })}
            onClick={() => {
              scrollTo(index);
            }}>
          </button>
              </li>
        )}
        </ul>
      }
    </div>);

}