import { Language } from "~/types";
import { de } from "./de";
import { en } from "./en";
import { it } from "./it";

const translations = {
  de,
  it,
  en,
};

export const translate = (language: Language) => translations[language];
