import { MemoExoticComponent, SVGProps } from "react";

interface Props {
  className?: string | undefined;
  component: MemoExoticComponent<
    (props: SVGProps<SVGSVGElement>) => JSX.Element
  >;
}

export default function Icon({ className, component: Component }: Props) {
  return <Component className={className} />;
}
