import { MemoExoticComponent, SVGProps } from "react";
import styles from "./amenityIcon.module.css";
import {
  Amenity1,
  Amenity10,
  Amenity11,
  Amenity12,
  Amenity13,
  Amenity14,
  Amenity15,
  Amenity154,
  Amenity155,
  Amenity156,
  Amenity157,
  Amenity158,
  Amenity159,
  Amenity16,
  Amenity160,
  Amenity161,
  Amenity162,
  Amenity163,
  Amenity164,
  Amenity165,
  Amenity166,
  Amenity168,
  Amenity169,
  Amenity17,
  Amenity170,
  Amenity171,
  Amenity172,
  Amenity173,
  Amenity174,
  Amenity175,
  Amenity176,
  Amenity177,
  Amenity18,
  Amenity19,
  Amenity2,
  Amenity20,
  Amenity21,
  Amenity22,
  Amenity23,
  Amenity24,
  Amenity25,
  Amenity26,
  Amenity27,
  Amenity28,
  Amenity29,
  Amenity3,
  Amenity30,
  Amenity31,
  Amenity32,
  Amenity33,
  Amenity34,
  Amenity35,
  Amenity36,
  Amenity37,
  Amenity38,
  Amenity39,
  Amenity4,
  Amenity40,
  Amenity41,
  Amenity42,
  Amenity43,
  Amenity44,
  Amenity45,
  Amenity46,
  Amenity47,
  Amenity48,
  Amenity485,
  Amenity486,
  Amenity49,
  Amenity496,
  Amenity497,
  Amenity498,
  Amenity5,
  Amenity50,
  Amenity51,
  Amenity52,
  Amenity53,
  Amenity54,
  Amenity55,
  Amenity56,
  Amenity57,
  Amenity58,
  Amenity59,
  Amenity6,
  Amenity60,
  Amenity61,
  Amenity62,
  Amenity63,
  Amenity7,
  Amenity8,
  Amenity9 } from
"./build/svg/amenities";
import Icon from "./icon";

interface Props {
  id: number;
}

export default function AmenityIcon({ id }: Props) {
  const icon = icons[id];
  return icon ? <Icon component={icon} className={styles.icon} /> : null;
}

const icons: {
  [id: number]: MemoExoticComponent<
    (props: SVGProps<SVGSVGElement>) => JSX.Element>;

} = {
  1: Amenity1,
  2: Amenity2,
  3: Amenity3,
  4: Amenity4,
  5: Amenity5,
  6: Amenity6,
  7: Amenity7,
  8: Amenity8,
  9: Amenity9,
  10: Amenity10,
  11: Amenity11,
  12: Amenity12,
  13: Amenity13,
  14: Amenity14,
  15: Amenity15,
  16: Amenity16,
  17: Amenity17,
  18: Amenity18,
  19: Amenity19,
  20: Amenity20,
  21: Amenity21,
  22: Amenity22,
  23: Amenity23,
  24: Amenity24,
  25: Amenity25,
  26: Amenity26,
  27: Amenity27,
  28: Amenity28,
  29: Amenity29,
  30: Amenity30,
  31: Amenity31,
  32: Amenity32,
  33: Amenity33,
  34: Amenity34,
  35: Amenity35,
  36: Amenity36,
  37: Amenity37,
  38: Amenity38,
  39: Amenity39,
  40: Amenity40,
  41: Amenity41,
  42: Amenity42,
  43: Amenity43,
  44: Amenity44,
  45: Amenity45,
  46: Amenity46,
  47: Amenity47,
  48: Amenity48,
  49: Amenity49,
  50: Amenity50,
  51: Amenity51,
  52: Amenity52,
  53: Amenity53,
  54: Amenity54,
  55: Amenity55,
  56: Amenity56,
  57: Amenity57,
  58: Amenity58,
  59: Amenity59,
  60: Amenity60,
  61: Amenity61,
  62: Amenity62,
  63: Amenity63,
  154: Amenity154,
  155: Amenity155,
  156: Amenity156,
  157: Amenity157,
  158: Amenity158,
  159: Amenity159,
  160: Amenity160,
  161: Amenity161,
  162: Amenity162,
  163: Amenity163,
  164: Amenity164,
  165: Amenity165,
  166: Amenity166,
  168: Amenity168,
  169: Amenity169,
  170: Amenity170,
  171: Amenity171,
  172: Amenity172,
  173: Amenity173,
  174: Amenity174,
  175: Amenity175,
  176: Amenity176,
  177: Amenity177,
  485: Amenity485,
  486: Amenity486,
  496: Amenity496,
  497: Amenity497,
  498: Amenity498
};