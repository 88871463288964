import classNames from "classnames";
import { useState } from "react";
import { translate } from "~/i18n";
import { Amenity, Language } from "~/types";
import styles from "./amenities.module.css";
import AmenityIcon from "./amenityIcon";
import { ArrowDown, ArrowUp } from "./build/svg";
import Container from "./container";
import Heading from "./heading";
import Icon from "./icon";

interface Props {
  language: Language;
  amenities: Amenity[];
}

export default function Amenities({ language, amenities }: Props) {
  const trans = translate(language);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <section className={styles.amenities}>
      <Container>
        <Heading level="h2" title={trans.ourAmenities} withSeparator={true} />

        <div className={styles.wrapper}>
          <ul
            className={classNames(styles.list, {
              [styles.listIsOpen ?? ""]: isOpen
            })}>

            {amenities.map(({ id, title }) =>
            <li key={id} className={styles.item}>
                <AmenityIcon id={id} />
                {title}
              </li>
            )}
          </ul>

          <button
            className={styles.moreButton}
            onClick={() => setIsOpen((value) => !value)}
            title={isOpen ? trans.showLess : trans.showMore}>

            <Icon
              className={styles.moreIcon}
              component={isOpen ? ArrowUp : ArrowDown} />

          </button>
        </div>
      </Container>
    </section>);

}