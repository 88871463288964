export const de = {
  bookNextHolidayHere: {
    formal: "Buchen Sie Ihren nächsten Urlaub hier!",
    informal: "Buche deinen nächsten Urlaub hier!",
  },
  yourHolidayWithUs: {
    formal: "Ihr Urlaub bei uns",
    informal: "Dein Urlaub bei uns",
  },
  ourAmenities: "Unsere Ausstattung",
  southTyrol: "Südtirol",
  italy: "Italien",
  imprint: "Impressum",
  privacy: "Datenschutz",
  contact: "Kontakt",
  propertyNotBookable:
    "Diese Unterkunft ist zurzeit leider nicht online buchbar.",
  showMore: "mehr anzeigen",
  showLess: "weniger anzeigen",
  enableJavaScript:
    "JavaScript muss im Browser aktiviert sein, anderenfalls ist hier keine Buchung möglich.",
  errorLoadingWidget:
    "Beim Laden des Buchungswidgets ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
};

export type Translations = typeof de;
