import { Translations } from "./de";

export const it: Translations = {
  bookNextHolidayHere: {
    formal: "Prenotate la Vostra prossima vacanza da noi",
    informal: "Prenota la tua prossima vacanza da noi",
  },
  yourHolidayWithUs: {
    formal: "La Vostra vacanza da noi",
    informal: "La tua vacanza da noi",
  },
  ourAmenities: "I nostri servizi",
  southTyrol: "Alto Adige",
  italy: "Italia",
  imprint: "Crediti",
  privacy: "Privacy",
  contact: "Contatto",
  propertyNotBookable:
    "Siamo spiacenti, questo alloggio non è attualmente prenotabile online.",
  showMore: "mostra di più",
  showLess: "mostra di meno",
  enableJavaScript:
    "È necessario attivare JavaScript nel browser per poter prenotare.",
  errorLoadingWidget:
    "Si è verificato un errore durante il caricamento del widget di prenotazione. Si prega di riprovare più tardi.",
};
