import { ReactNode, RefObject } from "react";
import { Close } from "./build/svg";
import Icon from "./icon";
import styles from "./popup.module.css";

interface Props {
  dialogRef: RefObject<HTMLDialogElement>;
  children: ReactNode;
}

export default function Popup({ children, dialogRef }: Props) {
  return (
    <dialog className={styles.popup} ref={dialogRef}>
      <button
        className={styles.closeButton}
        onClick={() => dialogRef.current?.close()}>

        <Icon className={styles.closeIcon} component={Close} />
      </button>
      <div className={styles.content}>{children}</div>
    </dialog>);

}