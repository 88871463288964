import type { LatLngLiteral } from "leaflet";
import { lazy, Suspense, useEffect, useRef, useState } from "react";
import styles from "./mapWrapper.module.css";

const LeafletMap = lazy(() => import("~/components/leafletMap.client"));

interface Props {
  propertyName: string;
  coordinates: LatLngLiteral;
}

export default function MapWrapper({ propertyName, coordinates }: Props) {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry?.isIntersecting) return;
        setIsVisible(true);
        observer.disconnect();
      },
      { rootMargin: "150px" }
    );
    ref.current && observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div id="map" className={styles.mapWrapper} ref={ref}>
      {isVisible ?
      <Suspense fallback={<div className={styles.loading}></div>}>
          {isVisible &&
        <LeafletMap coordinates={coordinates} propertyName={propertyName} />
        }
        </Suspense> :

      <div className={styles.loading}></div>
      }
    </div>);

}