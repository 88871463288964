import classNames from "classnames";
import styles from "./heading.module.css";

interface Props {
  level: "h1" | "h2";
  title: string;
  subtitle?: string;
  withSeparator: boolean;
}

export default function Heading({
  level: H,
  title,
  subtitle,
  withSeparator
}: Props) {
  return (
    <header
      className={classNames(styles.heading, {
        [styles.withSeparator ?? ""]: withSeparator
      })}>

      <H className={styles[H]}>{title}</H>
      {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
    </header>);

}